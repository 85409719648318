/*
####################################################
M E D I A  Q U E R I E S
####################################################
*/

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
    #about img {
        margin: 50px 0;
    }
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .intro h1 {
        font-size: 46px;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .intro h1 {
        font-size: 82px;
    }
}

/*
::::::::::::::::::::::::::::::::::::::::::::::::::::
Custom media queries
*/

/* Set width to make card deck cards 100% width */
@media (max-width: 950px) {
}
